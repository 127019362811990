import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { useList } from 'react-use'
import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, type } from '../styles/global'

const Archive = props => {
    const { previewData, pageContext } = props
    const graphQuery = useStaticQuery(query)
    const data = previewData || parseACF(graphQuery, 'allWordpressInfopages')
    const products_data = graphQuery?.allShopifyProduct?.nodes
    const [images, { set: setImages, push: pushImage }] = useList([])

    useEffect(() => {
        if (data.home_images) {
            setImages(data.home_images)
        }
        if (products_data) {
            products_data.forEach(item => {
                if (item.images) {
                    item.images.forEach(image => {
                        let imageRewrite
                        if (image.originalSrc) {
                            imageRewrite = image.originalSrc.replace('https://cdn.shopify.com/s','')
                        }
                
                        const imageSrc = imageRewrite || image.originalSrc
                        pushImage({
                            caption: item.title,
                            image: imageSrc
                        })
                    })
                }
            });
        }
    }, [])

    const renderImages = () => {
        if (!images) return
        return images.map((item, i) => {
            let imageRewrite
            if (item?.image?.sizes?.medium2) {
                imageRewrite = item?.image?.sizes?.medium2.replace('https://admin.illoom.com.au','')
            }
            const image = imageRewrite || item?.image
            const caption = item?.caption
            let orientation = 'false'
            if (item?.image?.height > item?.image?.width) {
                orientation = 'portrait'
            }
            if (!image) return false

            return (
                <Item
                    key={i}
                    orientation={orientation}
                >
                    <ImageWrapper>
                        <Image
                            key={image}
                            src={image}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>
                    {caption && (
                        <Description>
                            {caption}
                        </Description>
                    )}
                </Item>
            )
        })
    }

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <Grid>
                        {renderImages()}
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Description = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
    padding-top: 2rem;
    padding-bottom: 6rem;
`

const Grid = styled.div`
    ${tw`flex flex-wrap`};
    margin: 0 -1.5rem -2rem;
`

const ImageWrapper = styled.div``

const Item = styled.div`
    ${tw`flex flex-col w-1/6`};
    padding: 0 1.5rem;
    margin-bottom: 3rem;

    ${media.mob`
        ${tw`w-1/2`};
    `}

    ${ImageWrapper} {
        ${tw`relative w-full`};

        ${props => {
            if (props.orientation === 'portrait')
                return css`
                    ${tw`mx-auto`};
                    max-width: 75%;
                    max-height: 75%;
                `
        }}
    }

    ${Description} {
        margin-top: 0.3rem;
        ${type.caption}

        ${props => {
            if (props.orientation === 'portrait')
                return css`
                    ${tw`mx-auto`};
                    width: 75%;
                `
        }}
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "archive" } }) {
            nodes {
                acf_json
            }
        }
        allShopifyProduct {
            nodes {
                title
                
                images {
                    originalSrc
                }
            }
        }
    }
`

export default Archive
