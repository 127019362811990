import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMouse, useMedia } from 'react-use'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, type } from '../styles/global'

const Materials = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [ activeDescription, setActiveDescription ] = useState(false)
    const ref = React.useRef(null)
    const { elX, elY } = useMouse(ref)
    const isTablet = useMedia('(max-width: 1024px)');
    // console.log(activeDescription)
    console.log('data', data)

    const renderMetals = () => {
        if (!data.materials) return

        return data.materials.map((item, i) => {
            let imageRewrite
            if (item?.image?.sizes?.medium2) {
                imageRewrite = item?.image?.sizes?.medium2.replace('https://admin.illoom.com.au','')
            }
            const image = imageRewrite || item?.image?.sizes?.medium2
            return (
                <Item
                    key={i}
                >
                    <ImageWrapper
                        onMouseEnter={() => setActiveDescription(`${item?.title}`)}
                        onMouseLeave={() => setActiveDescription(false)}
                    >
                        {image && (
                            <Image
                                key={image}
                                src={image}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage 
                                            image={src} 
                                            style={{opacity: loading ? 0 : 1}}  
                                        />
                                    )
                                }}
                            </Image>
                        )}
                    </ImageWrapper>
                        <Heading>{item?.title}</Heading>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item?.text,
                        }}
                    />
                </Item>
            )
        })
    }

    return (
        <Layout
            meta={data && data.seo}
            headerOptions={{...data?.headerOptions}}
            cartLink={true}
        >
            <Wrapper
                ref={ref}
            >
                <Container>
                    <Metals>
                        {renderMetals()}
                    </Metals>
                </Container>
                {!isTablet && activeDescription && (
                    <Caption
                        style={{
                            left: elX && `${elX+16}px`,
                            top: elY && `${elY+4}px`,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: activeDescription,
                        }}
                    />
                )}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`relative w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Metals

const ImageWrapper = styled.div``
const Metals = styled.div`
    ${tw`flex justify-center flex-wrap`};
    margin-bottom: -8rem;
    padding-top: 4rem;
    padding-bottom: 6rem;

    ${Item} {
        ${tw`relative`};
        width: 43%;
        width: calc(43% - 7rem);
        margin-bottom: 8rem;

        ${media.tab`
            width: 43%;
        `}

        ${media.mob`
            ${tw`w-full`};
        `}

        &:nth-child(odd) {
            margin-right: 7rem;

            ${media.tab`
                margin-right: 7%;
            `}

            ${media.mob`
                ${tw`mr-0`};
            `}
        }

        &:nth-child(even) {
            margin-left: 7rem;

            ${media.tab`
                margin-left: 7%;
            `}

            ${media.mob`
                ${tw`ml-0`};
            `}
        }

        ${ImageWrapper} {
            display: flex;
            position: relative;
            height: 0;
            padding-bottom: 80%;
            &, * {
                cursor: none!important;
            }
        }

        ${BGImage} {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
        }

        ${Heading} {
            display: none;
            ${type.heading};
            margin-top: 1rem;

            ${media.tab`
                display: block;
            `}
        }

        ${Description} {
            ${type.caption}

            > * {
                margin-top: 1em;
            }
        }
    }
`

const Caption = styled.div`
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
    ${type.heading}
    line-height: 0.9;
    width: 20rem;
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "materials" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Materials
