import React, { useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMouse, useMedia } from 'react-use'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, type } from '../styles/global'

const ProductItem = ({index: i, item, image, isTablet, activeDescription, setActiveDescription}) => {
    const [active, setActive] = useState(false)
    return (
        <Item
            key={i}
            active={active}
        >
            <ImageWrapper
                onMouseEnter={() => !isTablet && setActiveDescription(`${item?.title}<br />From $${item?.priceRangeV2?.minVariantPrice?.amount}`)}
                onMouseLeave={() => !isTablet && setActiveDescription(false)}
                style={{ backgroundColor: !image && 'gray' }}
                onClick={() => {
                    if (isTablet) {
                        if (!active) {
                            setActive(true)
                        } else {
                            navigate(`/shop/${item.handle}`)    
                        }
                    } else {
                        navigate(`/shop/${item.handle}`)
                    }
                }}
            >
                {image && (
                    <Image
                        key={image}
                        src={image}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage 
                                    image={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image>
                )}
            </ImageWrapper>
            <Caption
                className={'tablet-caption'}
                dangerouslySetInnerHTML={{
                    __html: `${item?.title}<br />From $${item?.priceRangeV2?.minVariantPrice?.amount}`,
                }}
            />
        </Item>
    )
}

const Shop = props => {
    const { previewData, pageContext } = props
    const graphQuery = useStaticQuery(query)
    const data = previewData || parseACF(graphQuery, 'allWordpressInfopages')
    const products_data = graphQuery?.allShopifyCollection?.edges[0]?.node?.products
    const [ activeDescription, setActiveDescription ] = useState(false)
    const ref = React.useRef(null)
    const { elX, elY } = useMouse(ref)
    const isTablet = useMedia('(max-width: 1024px)');
    console.log('data', products_data)
    // console.log(activeDescription)

    const renderProducts = () => {
        if (!products_data) return

        return products_data.map((item, i) => {
            let imageRewrite
            if (item?.images[0]?.originalSrc) {
                imageRewrite = item?.images[0]?.originalSrc.replace('https://cdn.shopify.com/s','')
            }
    
            const image = imageRewrite || item?.images[0]?.originalSrc
            return (
                <ProductItem
                    key={i}
                    index={i}
                    item={item}
                    image={image}
                    isTablet={isTablet}
                    setActiveDescription={setActiveDescription}
                    activeDescription={activeDescription}
                />
            )
        })
    }

    return (
        <Layout
            meta={data && data.seo}
            headerOptions={{...data?.headerOptions}}
            cartLink={true}
        >
            <Wrapper
                ref={ref}
            >   
                <LogoMobile
                    onClick={() => navigate(`/`)}
                >
                    <Image
                        key={'illoom-logo'}
                        src={require('../assets/images/logos/illoom-logo-mobile.svg')}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image> 
                </LogoMobile>
                <Container>
                    <Products>
                        {renderProducts()}
                    </Products>
                </Container>
                {activeDescription && (
                    <Caption
                        className={'desktop-caption'}
                        style={{
                            left: elX && `${elX+16}px`,
                            top: elY && `${elY+4}px`,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: activeDescription,
                        }}
                    />
                )}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`relative w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Mobile Logo

const LogoMobile = styled.div`
    ${container}
    margin-top: 5px;
    padding: 0 1rem;
    width: 100%;
    height: auto;
    display: none;

    ${media.mob`
        display: block;
    `}
`

// Products

const ImageWrapper = styled.div``
const Products = styled.div`
    ${tw`flex justify-center flex-wrap`};
    margin-bottom: -8rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
`

// Product Item

const Item = styled.div`
    ${tw`relative`};
    width: 43%;
    width: calc(43% - 7rem);
    margin-bottom: 8rem;

    ${media.tab`
        width: 43%;
    `}

    ${media.mob`
        ${tw`w-full`};
    `}

    &:nth-child(odd) {
        margin-right: 7rem;

        ${media.tab`
            margin-right: 7%;
        `}

        ${media.mob`
            ${tw`mx-5`};
        `}
    }

    &:nth-child(even) {
        margin-left: 7rem;

        ${media.tab`
            margin-left: 7%;
        `}

        ${media.mob`
            ${tw`mx-5`};
        `}
    }

    ${ImageWrapper} {
        display: flex;
        position: relative;
        height: 0;
        padding-bottom: 134%;
        &, * {
            cursor: none!important;
        }

        ${props => {
            if (props.active)
                return css`
                    background-color: black;
                `
        }}
    }

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
        object-fit: cover;

        ${props => {
            if (props.active)
                return css`
                    opacity: 0.5!important;
                `
        }}
    }

    ${media.tab`
        .tablet-caption {
            display: none;

            ${props => {
                if (props.active)
                    return css`
                        display: block!important;
                    `
            }}
        }
    `}
`

const Caption = styled.div`
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
    ${type.heading}
    width: 424px;

    &.tablet-caption {
        display: none;
    }

    &.desktop-caption {
        ${media.tab`
            display: none;
        `}
    }

    ${media.tab`
        top: 50%;
        left: 50%;
        width: calc(100% - 2rem);
    `}

    ${media.tabPor`
        font-size: 1.8rem;
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "shop" } }) {
            nodes {
                acf_json
            }
        }
        allShopifyCollection(filter: { handle: { eq: "all" } }) {
            edges {
                node {
                    products {
                        title
                        handle
                        
                        images {
                            originalSrc
                        }
        
                        priceRangeV2 {
                            minVariantPrice {
                                amount
                            }
                        }
                    }
                }
            }
        }
    }
`


export default Shop
