import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage } from '../styles/global'

import { IsMobileContext } from '../context/IsMobileContext'

const Index = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    console.log('data', data)

    return (
        <Layout
            meta={data && data.seo}
            headerOptions={{
                template: 'home',
                images: data.home_images
            }}
            isHome={true}
        >
            <Wrapper>
                <Container>

                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
